import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLinkedinIn, faGithubAlt, faBitbucket, faCodepen, faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { faUserAstronaut, faToolbox, faChevronUp, faCameraRetro, faMailBulk } from '@fortawesome/free-solid-svg-icons'

export const SOCIAL = [
  {
    title: 'Stefano Frasca on Linkedin',
    type: 'work',
    link: 'https://www.linkedin.com/in/stefanofrasca',
    component: <FontAwesomeIcon icon={faLinkedinIn} />,
    classTile: '',
  },
  {
    title: 'Stefano Frasca\'s Mail',
    type: 'work',
    link: 'mailto:info@stefanofrasca.com',
    component: <FontAwesomeIcon icon={faMailBulk} />,
    classTile: '',
  },
  {
    title: 'Stefano Frasca on Github',
    type: 'work',
    link: 'https://github.com/fefoweb',
    component: <FontAwesomeIcon icon={faGithubAlt} />,
    classTile: '',
  },
  {
    title: 'Stefano Frasca on Bitbucket',
    type: 'work',
    link: 'https://bitbucket.org/fefoweb',
    component: <FontAwesomeIcon icon={faBitbucket} />,
    classTile: '',
  },
  {
    title: 'Stefano Frasca on About.me',
    type: 'work',
    link: 'https://about.me/stefanofrasca',
    component: <FontAwesomeIcon icon={faUserAstronaut} />,
    classTile: '',
  },
  {
    title: 'Stefano Frasca on Codepen',
    type: 'work',
    link: 'https://codepen.io/fefoweb',
    component: <FontAwesomeIcon icon={faCodepen} />,
    classTile: '',
  },
  {
    title: 'Stefano Frasca on Codesandbox',
    type: 'work',
    link: 'https://codesandbox.io/u/fefoweb',
    component: <FontAwesomeIcon icon={faToolbox} />,
    classTile: '',
  },
  {
    title: 'Stefano Frasca on Expo.io',
    type: 'work',
    link: 'https://expo.io/snacks/@fefoweb',
    component: <FontAwesomeIcon icon={faChevronUp} />,
    classTile: '',
  },
  {
    title: 'Contact me on Whatsupp',
    type: 'contact',
    link: 'https://wa.me/00393286976720',
    component: <FontAwesomeIcon icon={faWhatsapp} />,
    classTile: '',
  },
  {
    title: 'Stefano Frasca on GuruShots',
    type: 'work',
    link: 'https://gurushots.com/stefano.frasca/photos',
    component: <FontAwesomeIcon icon={faCameraRetro} />,
    classTile: ''
  }
];
