import React from 'react';
import { Link } from 'gatsby';
import useSiteMetadata from '../hooks/use-site-config';
import { FooterWrapper } from './styles/Footer';
import SocialButton from '../../content/components/SocialButton';

const Footer = () => {
  const { authorName, websiteHost, footerLinks } = useSiteMetadata();

  const FooterItem = ({ item }) => {
    if (item.url.startsWith('/')) {
      return (
        <span className="footer-item">
          <Link className="footer-link" to={item.url}>
            {item.label}
          </Link>
        </span>
      );
    }
    return (
      <span className="footer-item">
        <a className="footer-link" href={item.url}>
          {item.label}
        </a>
      </span>
    );
  };

  const FooterColumn = ({ column }) => {
    return (
      <div className="footer-col">
        <h5 className="footer-title" key={column.sectionName}>
          {column.sectionName}
        </h5>
        {column.links.map((item, i) => {
          return <FooterItem item={item} key={`footer-column-item-${i}`} />;
        })}
      </div>
    );
  };

  return (
    <FooterWrapper>
      <nav>
        <div className="footer-col">
          <h5 className="footer-title">
            {authorName} ©{new Date().getFullYear()}
          </h5>
          <div className="footer-row">
            <p className="footer-item-text">
              Built with{' '}
              <a className="footer-link" href="https://www.gatsbyjs.org">
                Gatsby
              </a>{' '}
              and{' '}
              <a
                className="footer-link"
                href="https://github.com/maxpou/gatsby-starter-morning-dew"
                rel="nofollow"
              >
                morning-dew
              </a>
            </p>
            <p className="footer-item-text">
              Hosted with{' '}
              <span className="footer-heart" role="img" aria-label="Love">
                ❤
              </span>{' '}
              <a className="footer-link" href={websiteHost.url} rel="nofollow">
                {websiteHost.name}
              </a>
              .
            </p>
          </div>
          <SocialButton />
        </div>
        {footerLinks.map((column, i) => {
          return <FooterColumn column={column} key={`footer-column-${i}`} />;
        })}
      </nav>
    </FooterWrapper>
  );
};

export default Footer;
