import React from 'react';
import Helmet from 'react-helmet';
import { withPrefix } from 'gatsby';
import useSiteMetadata from '../hooks/use-site-config';

const SEO = props => {
  const { isBlogPost, path = '', lang = 'en' } = props;
  const {
    siteTitle,
    siteUrl,
    siteCover,
    siteDescription,
    twitterUsername,
    siteDescriptionSEO,
  } = useSiteMetadata();

  const title = props.title
    ? `${props.title} | ${siteTitle}`
    : `${siteTitle} - ${siteDescription}`;
  const formatedSiteUrl = siteUrl.endsWith('/')
    ? siteUrl.substring(0, siteUrl.length - 1)
    : siteUrl;
  const imagePath = props.imageShare || props.cover || withPrefix(siteCover);
  const image = `${formatedSiteUrl}${imagePath}`;
  const description = props.description || siteDescriptionSEO;
  const twitterHash = !!twitterUsername ? `@${twitterUsername}` : '';
  const onlyTitle = props.title || siteTitle;

  return (
    <React.Fragment>
      <Helmet title={title}>
        {/* General tags */}
        <title>{title}</title>
        <html lang={lang} />
        <meta name="description" content={description} />
        <link rel="canonical" href={formatedSiteUrl + withPrefix(path)} />

        {/* OpenGraph tags */}
        <meta property="og:url" content={formatedSiteUrl + withPrefix(path)} />
        <meta property="og:type" content={isBlogPost ? 'article' : 'website'} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={image} />
        <meta property="og:site_name" content={onlyTitle} />
        <meta property="og:locale" content={lang} />

        {/* Twitter Card tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:creator" content={twitterUsername} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={image} />
        <meta name="twitter:site" content={twitterHash} />
      </Helmet>
    </React.Fragment>
  );
};

export default SEO;
